<template>
  <v-app class="grey lighten-4">
    <section class="py-5 h-100 d-flex justify-content-center main-div-login">
      <v-card
        elevation="2"
        outlined
        :class="webbreakdown() ? 'w-100' : 'w-75'"
        class="m-auto login-subdiv"
      >
        <v-card-text class="main-content-div">
          <v-row class="w-100 m-0">
            <v-col cols="12" sm="12" lg="6" class="image-div">
              <h2 class="text-primary-color">{{ $t("qrate") }}</h2>
              <br />
              <img
                alt="QRate"
                class="logo_img_lg w-100 h-75"
                src="../../assets/two_factor_authentication.png"
                style="max-height: 75% !important"
              />
            </v-col>

            <v-col cols="12" sm="12" lg="6" class="pb-2 login-main-div">
              <div class="w-100 m-auto p-2">
                <h2
                  class="text-center header-main-div text-primary-color font-weight-bold"
                >
                  {{ $t("twoFactorAuth") }}
                </h2>
                <br />
                <v-alert
                  v-if="alertMsg.status"
                  :timeout="alertMsg.timeout"
                  :color="alertMsg.color"
                  :type="alertMsg.type"
                  dismissible
                >
                  {{ alertMsg.text }}
                </v-alert>

                <form class="mb-4">
                  <label class="text-midgray-color">
                    {{ $t("twoFactorAuthLable") }}
                  </label>
                  <div
                    class="m-auto position-relative"
                    style="max-width: 300px"
                  >
                    <v-otp-input
                      length="6"
                      type="number"
                      v-model="otpEntered"
                      :disabled="isOTPVerified"
                      @input="$v.otpEntered.$touch()"
                      @blur="$v.otpEntered.$touch()"
                      :error-messages="otpErrors"
                      class="font-weight-bold"
                    ></v-otp-input>

                    <h6 class="text-dark" v-if="timerCount > 0">
                      {{ $t("otp will expire in the next") }}
                      <span class="font-weight-bold">{{ timerCount }}</span>
                      {{ $t("seconds") }}
                    </h6>

                    <div
                      class="text-center d-flex justify-content-center align-items-baseline"
                      v-if="timerCount === 0"
                    >
                      <span class="text-dark"
                        >{{ $t("did_not_receive_the_otp") }}?</span
                      >
                      <v-btn
                        class="text-capitalize"
                        color="primary"
                        @click="getOTPCode"
                        text
                        :loading="isOTPLoading"
                        >{{ $t("resend_otp") }}</v-btn
                      >
                    </div>

                    <v-overlay absolute :value="isOTPVerified">
                      <v-progress-circular
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                    </v-overlay>
                  </div>

                  <v-btn
                    block
                    rounded
                    color="primary"
                    @click="onOTPVerify"
                    class="btn-primary mt-4 text-capitalize"
                  >
                    {{ $t("confirm_otp") }}
                  </v-btn>

                  <div
                    class="text-dark mt-4 d-flex justify-content-center align-items-baseline"
                  >
                    <span>Back to</span>
                    <v-btn
                      class="text-capitalize text-primary-color p-2"
                      color="primary"
                      plain
                      @click="$router.push('/login')"
                    >
                      {{ $t("loginPage") }}?
                    </v-btn>
                  </div>
                </form>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </section>
  </v-app>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import { mapMutations } from "vuex";
import { webbreakdown } from "../../utils/breakpoints";
import userservice from "@/services/UserService/userservice";
import alerts from "@/mixins/alerts";
export default {
  name: "TwoFactorAuth",
  mixins: [alerts],
  mounted() {
    this.login_type = this.$route.query.login_type;
    this.getOTPCode();
  },
  data() {
    return {
      webbreakdown,
      otpEntered: "",
      userName: "",
      login_type: "",
      isOTPVerified: false,
      dismissSecs: 5,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      alertMsg: {
        status: false,
        text: "",
        timeout: 2000,
        color: "blue-grey",
        type: "success",
      },
      isOTPEnable: false,
      timerCount: 0,
      isOTPLoading: false,
    };
  },
  validations: {
    otpEntered: {
      required,
    },
  },
  watch: {
    timerCount: {
      handler(value) {
        // console.log("isOTPEnable", this.isOTPEnable)
        if (value > 0 && this.isOTPEnable) {
          // Time interval to run every 1 sec.
          setTimeout(() => {
            // stop interval when time out reaches to zero.
            if (this.timerCount === 0) return;
            this.timerCount--;
          }, 1000);
        }
      },
      immediate: true,
    },
  },
  computed: {
    otpErrors() {
      const errors = [];
      if (!this.$v.otpEntered.$dirty) return errors;
      !this.$v.otpEntered.required && errors.push("OTP is required");
      return errors;
    },
    ...mapGetters("Authentication"),
  },
  methods: {
    ...mapMutations(["setUserDetails"]),
    async getOTPCode() {
      this.userName = sessionStorage.getItem("username");
      if (!this.userName) return;
      this.isOTPLoading = true;
      try {
        let responseData;
        this.login_type === "mobile"
          ? (responseData = await userservice.get_otp_Mobile2FA({
              mobile_number: this.userName,
              user_type: sessionStorage.getItem("selected_user_type"),
            }))
          : (responseData = await userservice.get_otp_Email2FA({
              user_email: this.userName,
              user_type: sessionStorage.getItem("selected_user_type"),
            }));

        this.isOTPLoading = false;
        console.log("responseData", responseData);
        if (responseData && responseData.data.success) {
          this.isOTPEnable = true;
          this.timerCount = 300;
          this.showAlertSuccess(responseData.data.message);
        } else {
          this.isOTPEnable = false;

          this.showAlertError(responseData.data.message);
        }
      } catch (err) {
        this.isOTPLoading = false;
        this.isOTPEnable = false;

        this.showAlertError("something went wrong please try again sometime!");
      }
    },
    async onOTPVerify() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.isOTPVerified = true;
      try {
        const payloaddata = {
          mobile_number: this.login_type === "mobile" ? this.userName : null,
          user_email: this.login_type === "email" ? this.userName : null,
          otp: this.otpEntered,
          user_type: sessionStorage.getItem("selected_user_type"),
        };
        let responseData;
        this.userName === "mobile"
          ? (responseData = await userservice.confirm_otp_Mobile2FA(
              payloaddata
            ))
          : (responseData = await userservice.confirm_otp_Email2FA(
              payloaddata
            ));
        console.log("status_code", responseData);
        if (responseData.data.success) {
          this.showAlertSuccess(responseData.data.message);
          console.log("token ", responseData.data.data[0]);
          this.setUserDetails(responseData.data.data[0]);
          if (responseData.data.data[0].user_type) {
            sessionStorage.setItem(
              "user_type",
              responseData.data.data[0].user_type
            );
          }
          this.$setLocalStorage(
            "is_payment_gateway",
            responseData.data.data[0].is_payment_gateway
          );
           sessionStorage.setItem("selected_login_type", this.login_type);
           sessionStorage.setItem("selected_user_name", this.userName);
          this.$setLocalStorage(
            "supported_payment_modes",
            responseData.data.data[0].supported_payment_modes
          );
          this.$setLocalStorage(
            "is_check_pin",
            responseData.data.data[0].is_check_pin
          );
          sessionStorage.setItem(
            "is_self_beneficiary",
            responseData.data.data[0].is_self_beneficiary ? true : false
          );
          this.$setLocalStorage(
            "is_partner",
            responseData.data.data[0].is_partner
          );
          this.$setLocalStorage("logo", responseData.data.data[0].logo);
          this.$setLocalStorage("is_logo", responseData.data.data[0].is_logo);
          this.$setLocalStorage(
            "partner_type",
            responseData.data.data[0].partner_type
          );
          sessionStorage.setItem(
            "access_token",
            responseData.data.data[0].access_token
          );
          sessionStorage.setItem(
            "refresh_token",
            responseData.data.data[0].refresh_token
          );
          sessionStorage.setItem("user_id", responseData.data.data[0].user_id);
          console.log("access", responseData.data.data[0].access_token);
          console.log("refersh", responseData.data.data[0].refresh_token);
          sessionStorage.setItem(
            "complete_registration",
            responseData.data.data[0].complete_registration
          );
          sessionStorage.setItem(
            "is_kyc",
            responseData.data.data[0].kyc_status
          );

          this.$store.commit(
            "access_token",
            responseData.data.data[0].access_token
          );
          this.$store.commit(
            "complete_registration",
            responseData.data.data[0].complete_registration
          );

          this.isOTPEnable = false;
          this.isOTPVerified = false;

          document.cookie = "2FA_status=enabled";
          document.cookie = `mobile_number=${this.userName}`;
          this.$router.push({ name: "HomeView" });
        } else {
          this.isOTPEnable = true;
          this.isOTPVerified = false;
          this.showAlertError(responseData.data.message);
        }
      } catch (err) {
        this.isOTPVerified = false;
        this.isOTPEnable = true;
        this.showAlertError("something went wrong please try again sometime!");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/global.scss";
.lead {
  font-weight: 400;
  color: $txt;
}
.logo_img {
  max-height: 50px;
}
.logo_img_lg {
  max-height: 100px;
}
.div-signup {
  color: $txt;
  cursor: pointer;
}
.login {
  height: 100%;
}
.invalid-feedback-email {
  display: flex;
  flex-direction: column;
  text-align: initial;
  font-size: 12px;
  background-color: #f5f5f5;
  color: #dc3545;
}
.invalid-feedback-password {
  display: flex;
  flex-direction: column;
  text-align: initial;
  font-size: 12px;
  background-color: #f5f5f5;
  color: #dc3545;
}
.language-selection {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.button#input-type__BV_toggle_ {
  font-size: 10px;
}
.forgot-link {
  margin-top: 26px;
  line-height: 3rem;
}

.shadow_class {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  // width: %;
  background: #f8f9fa;
  border-radius: 7px;
}
</style>
